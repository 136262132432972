/*
 * Site Footer
 *
 * This file contains styles that are specific to the site footer.
 *
 *****************************************************************************/

.site-footer {
    @include font-size(14px);

    color: $gray400;

    &__layout {
        border-top: 1px solid $gray800;
        display: flex;
        justify-content: space-between;
        padding: $spacer 0 calc(#{$spacer} + 20px);
    }
}
