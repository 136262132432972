/*
 * Page Header
 *
 * This file contains styles that are specific to the page header.
 *
 *****************************************************************************/

.page-header {
    @include size(100%, auto);

    position: relative;

    &__text-lockup {
    }
    &__cover {
        padding: 6.5rem $gutter #{$gutter / 2};

        @include mq($until: small) {
            min-height: 85vh;
        }
        @include mq(small) {
            padding-top: $spacer-x3;
        }
    }
    &__image {
        @include size(auto, 50vh);
        @include position(absolute, null, #{$gutter}, 5rem, #{$gutter}, -1);

        background: url('../images/dylan-garner-portrait.jpg') no-repeat center
            center;
        background-size: cover;
        margin: 0 auto;
        max-width: 400px;
        overflow: hidden;

        @include mq(small) {
            bottom: 0;
            height: 60vh;
            max-width: 500px;
        }
        @media screen and (min-height: 800px) {
            background-position-y: bottom;
        }

        &::after {
            @include size(100%);
            @include position(absolute, 0, null, null, 0, 2);

            background-color: lighten($color-orange, 20%);
            content: '';
            transform-origin: left top;
            transition: transform 1s cubic-bezier(0.16, 1, 0.3, 1) 0.5s;
        }
    }
    &__bottom {
        display: flex;
        margin-top: auto;
    }
    &__marquee {
        align-items: flex-end;
        margin-left: auto;
    }
    &__contact {
        @include font-size(16px);

        margin-right: auto;

        a {
            display: inline-block;
            font-weight: $font-weight-medium;
            text-transform: uppercase;

            &::before {
                @include size(16px, 18px);

                animation-duration: 2.5s;
                animation-iteration-count: infinite;
                animation-name: wave-animation;
                display: inline-block;
                transform-origin: 70% 70%;

                background: url('../images/icon-hand.svg') no-repeat center
                    center;
                content: '';
                display: block;
                margin: 0 auto 0.5rem;
            }
        }
    }
}

.page-headline {
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;

    .word-wrap {
        display: block;
        opacity: 0;
        transform: translate3d(0, 12%, 0);
        transition: opacity 1s cubic-bezier(0.16, 1, 0.3, 1),
            transform 1s cubic-bezier(0.16, 1, 0.3, 1);

        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                transition-delay: #{$i * 0.12}s;
            }
        }
    }
    .font-serif {
        @include font-size(130px);

        line-height: $line-height-base;
        position: relative;
        text-transform: none;
    }
}

body.is-loaded {
    .page-header {
        &__image::after {
            transform: scaleY(0);
        }
    }
    .page-headline {
        .word-wrap {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
}
