/*
 * Project
 *
 * This file contains all styles related to the project component.
 *
 *****************************************************************************/

.project {
    position: relative;

    &-image {
        @include size(200px, 150px);
        @include position(absolute, 0, null, null, 0, 5);

        background: #fff;
        display: block;
        overflow: hidden;
        // pointer-events: none;
    }
}

.project3 {
    position: relative;
}
