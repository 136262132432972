/*
 * Background Image
 *
 * This file contains all styles related to the bg-image component.
 *
 *****************************************************************************/

.bg-image {
    @include size(100%, 100%);
    @include position(absolute, 0, null, null, 0, 1);

    background: transparent no-repeat center center;

    &--cover {
        background-size: cover;
    }
    &--contain {
        background-size: contain;
    }
}
