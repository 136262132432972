/*
 * Helper Classes
 *
 * This file contains CSS helper classes.
 *
 *****************************************************************************/

// Clear inner floats
.clearfix {
    @include clearfix;
}

// Hide text, commonly used to show an image instead
.hide-text {
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
}

// Hide element while making it readable for screen readers
.visually-hidden {
    @include screen-reader;
}

// Alignment helpers
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.m-auto {
    @include auto;
}

.float-left {
    float: left;
}
.float-right {
    float: right;
}

// Display helpers
.d-block {
    display: block;
}
.d-inline {
    display: inline;
}
.d-inblock {
    display: inline-block;
}

// Z helpers
.z-above {
    z-index: 2;
}

.z-below {
    z-index: -1;
}

// Position helpers
.pos-rel {
    position: relative;
}

// Responsive media
.responsive-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe,
    object,
    embed,
    video,
    img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
