/*
 * Webfonts
 *
 * This file contains all @font-face declarations, if any.
 *
 *****************************************************************************/

@font-face {
    font-family: 'Founders Grotesk';
    src: url('../fonts/FoundersGrotesk-Regular.woff2') format('woff2'),
        url('../fonts/FoundersGrotesk-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: url('../fonts/FoundersGrotesk-Medium.woff2') format('woff2'),
        url('../fonts/FoundersGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GrandSlang';
    src: url('../fonts/GrandSlang-Roman.woff2') format('woff2'),
        url('../fonts/GrandSlang-Roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GrandSlang';
    src: url('../fonts/GrandSlang-Italic.woff2') format('woff2'),
        url('../fonts/GrandSlang-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
