/*
 * Layout Primitive: Center
 *
 * This file contains styles that are specific to the center layout.
 *
 *****************************************************************************/

.center {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    // max-width: 75ch;
    padding-left: $gutter;
    padding-right: $gutter;

    @include mq(small) {
        padding-left: $spacer;
        padding-right: $spacer;
    }

    &--intrinsic {
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}
