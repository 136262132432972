/*
 * Layout Primitive: Cover
 *
 * This file contains styles that are specific to the cover layout.
 *
 *****************************************************************************/

.cover {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: $gutter;

    > * {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    > .centered {
        margin-bottom: auto;
        margin-top: auto;
    }

    &--spaced {
        padding: $spacer-x2 $gutter;
    }
}

.cover > :first-child:not(.centered) {
    margin-top: 0;
}

.cover > :last-child:not(.centered) {
    margin-bottom: 0;
}
