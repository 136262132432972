/*
 * Site Header
 *
 * This file contains styles that are specific to the site header.
 *
 *****************************************************************************/

.site-header {
    @include size(calc(100% - #{$gutter * 2}), auto);
    @include position(absolute, #{$gutter}, 0, null, 0, 98);

    margin: auto;

    &__layout {
        @include font-size(16px);

        color: $black;
        display: flex;
        justify-content: space-between;

        * {
            color: inherit;
        }
    }
    &__title,
    &__text {
        font-weight: $font-weight-medium;
        line-height: $line-height-xs;
        text-align: center;
        text-transform: uppercase;
    }
}
