/*
 * Layout Primitive: Cluster
 *
 * This file contains styles that are specific to the cluster layout.
 *
 *****************************************************************************/

.cluster {
    overflow: hidden;

    > * {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: calc(#{$gutter} / 2 * -1);
    }
    > * > * {
        margin: calc(#{$gutter} / 2);
    }
}
