/*
 * List
 *
 * This file contains all styles related to lists.
 *
 *****************************************************************************/

ul:not([class]),
ol:not([class]) {
    li {
        margin: 0 0 0.25rem 0;
    }
    ul {
        li {
            margin: 0 0 0.25rem 0;
        }
    }
}

ul.list,
ol.list {
    li,
    &__item {
        margin: 0 0 0.25rem 0;
    }
    &--inline {
        li,
        &__item {
            display: inline;

            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }
}

.block-list {
    position: relative;

    &__item {
        @include font-size(14px);

        align-items: center;
        border-bottom: 1px solid $gray800;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: $gutter;
        padding-top: $gutter;

        &:first-child {
            border-top: 1px solid $gray800;
        }

        &-col {
            &:not(:last-child) {
                margin-right: 1rem;
            }
            &:first-child {
                flex: 2 1 50%;
                margin-bottom: 0.5rem;

                @include mq(small) {
                    flex-basis: 0;
                    margin-bottom: 0;
                }
            }
            &:nth-child(2) {
                flex: 1 1 100%;

                @include mq($until: small) {
                    @include font-size(12px);

                    margin-right: 0;
                    order: 4;
                }
                @include mq(small) {
                    flex-basis: 0;
                }
            }
            &:nth-child(3) {
                flex: 2 1 auto;

                @include mq($until: small) {
                    order: 3;
                }
                @include mq(small) {
                    flex-basis: 0;
                }
            }
            &:last-child {
                flex: 0 1 auto;
            }
        }
    }
    &__item-title {
        @include size-h3;
    }
}
