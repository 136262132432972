/*
 * Page: Home
 *
 * This file contains styles that are specific to the home page.
 *
 *****************************************************************************/

.home {
}

.info {
    @include mq(small) {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &-contact {
        @include mq($until: small) {
            @include size(280px);

            margin: 0 auto 3rem;
        }
        @include mq(small) {
            flex: 1 1 30%;
            margin-right: $gutter-percent * 2;
        }
    }
    &-text {
        @include mq(small) {
            flex: 1 1 60%;
        }
    }
}

.social {
    &__link {
        color: $white;
        text-decoration: underline;
    }
}

.contact-btn {
    background-color: $white;
    border-radius: 100%;
    display: block;
    height: 0;
    outline: none;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    transition: background-color 0.3s $ease-in-out-quick;
    z-index: 3;

    // box-shadow: 2px 2px 0 $color-purple, -2px -2px 0 $color-highlight;

    @include on-event {
        background-color: $color-highlight;
    }

    &__text {
        @include position(absolute, 0, null, null, 0, 1);
        @include size(100%);

        align-items: center;
        color: $color-charcoal;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }
}
