/*
 * Blockquote
 *
 * This file contains all styles related to an blockquote component.
 *
 *****************************************************************************/

.blockquote {
    position: relative;
    quotes: '“' '”' '‘' '’';

    &__text {
        @include font-size(24px);

        position: relative;

        &::before {
            @include position(relative, 0, null, null, -0.25rem);

            color: $color-primary;
            content: open-quote;
        }
        &::after {
            @include position(relative, 0, 0, null, null);

            color: $color-primary;
            content: close-quote;
        }
    }
    &__footer {
        font-style: normal;

        &::before {
            content: '— ';
        }
    }
}
