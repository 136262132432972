/*
 * Marquee
 *
 * This file contains all styles related to the marquee component.
 *
 *****************************************************************************/

.marquee {
    display: flex;
    flex: 0 0 auto;
    max-width: 150px;
    overflow: hidden;
    position: relative;

    @include mq(small) {
        max-width: 300px;
    }

    &__inner {
        align-items: center;
        animation: marquee 5s linear infinite;
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-start;
    }
    &__text {
        color: $color-orange;
        flex: 0 0 auto;
        font-weight: $font-weight-medium;
        padding-right: 2rem;
        position: relative;
        text-transform: uppercase;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}
