/*
 * Layout Primitive: Stack
 *
 * This file contains styles that are specific to the stack layout.
 *
 *****************************************************************************/

.stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > * {
        margin-bottom: 0;
        margin-top: 0;
    }
    > * + * {
        margin-top: 7rem;
    }
}
