/*
 * Spacing
 *
 * Utility classes to put specific spacing values onto elements.
 * The below loop will generate us a suite of responsive classes like.
 *
 *****************************************************************************/

// Example:
// mt-sm-2 mt-md-5 mt-lg-7 mt-xl-9
// pb-sm-2 pb-md-5 pb-lg-7 pb-xl-9

$spacing-breakpoints: (
    '': 0,
    xs: map-get($mq-breakpoints, xsmall),
    sm: map-get($mq-breakpoints, small),
    md: map-get($mq-breakpoints, medium),
    lg: map-get($mq-breakpoints, large),
    xl: map-get($mq-breakpoints, xlarge)
);

$sides: (
    '': '',
    't': 'top',
    'b': 'bottom',
    'l': 'left',
    'r': 'right'
);

$unit: 'rem';

@each $breakName, $breakValue in $spacing-breakpoints {
    @each $sideName, $sideValue in $sides {
        @for $i from 0 through 10 {
            $property: if($sideName == '', '', -#{$sideValue});
            $space: $i * 1;
            $selector: '';

            @if $breakName != '' {
                $selector: #{$sideName}-#{$breakName}-#{$i};
            } @else {
                $selector: #{$sideName}-#{$i};
            }

            @if $breakName != '' {
                @media (min-width: $breakValue) {
                    .m#{$selector} {
                        margin#{$property}: #{$space + $unit};
                    }

                    .p#{$selector} {
                        padding#{$property}: #{$space + $unit};
                    }
                }
            } @else {
                .m#{$selector} {
                    margin#{$property}: #{$space + $unit};
                }

                .p#{$selector} {
                    padding#{$property}: #{$space + $unit};
                }
            }
        }
    }
}

// Additional helpers
.mb-none {
    margin-bottom: 0 !important;
}

.mb-half {
    margin-bottom: 0.5rem !important;
}

.mb-quarter {
    margin-bottom: 0.25rem !important;
}

.mt-none {
    margin-top: 0 !important;
}

.mt-half {
    margin-top: 0.5rem !important;
}

.mt-quarter {
    margin-top: 0.25rem !important;
}

.pb-none {
    padding-bottom: 0 !important;
}

.pb-half {
    padding-bottom: 0.5rem !important;
}

.pb-quarter {
    padding-bottom: 0.25rem !important;
}

.pt-none {
    padding-top: 0 !important;
}

.pt-half {
    padding-top: 0.5rem !important;
}

.pt-quarter {
    padding-top: 0.25rem !important;
}
