/*
 * Logo
 *
 * This file contains all styles related to the logo component.
 *
 *****************************************************************************/

.logo {
    display: block;
}
