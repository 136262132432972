/*
 * Typography
 *
 * This file collects all of the type variables used throughout the website.
 *
 *****************************************************************************/

$base-font-size: 16px;

// Sans-serif font family
// @type List
$font-sans: 'Founders Grotesk', 'Helvetica Neue', 'Helvetica', 'Arial',
    sans-serif !default;

// Serif font family
// @type List
$font-serif: 'GrandSlang', 'Georgia', 'Times New Roman', serif !default;

// Font weight
$font-weight-thin: 100;
$font-weight-xlight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;
$font-weight-xbold: 800;
$font-weight-black: 900;

// Letter spacing
$kerning25: 0.03125rem;
$kerning50: 0.0625rem;
$kerning100: 0.125rem;
$kerning150: 0.1875rem;
$kerning200: 0.25rem;
$kerning250: 0.3125rem;

// Line height
$line-height-base: 1;
$line-height-xs: 1.125;
$line-height-sm: 1.25;
$line-height-md: 1.5;
$line-height-lg: 1.75;
$line-height-xlg: 1.95;
